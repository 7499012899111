@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
iframe {
  height: 100%;
  width: 100%;
}